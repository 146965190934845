<template>
  <button
    class="rounded-full bg-transparent hover:bg-orange hover:text-white hover:transition-colors duration-300 border-orange border-2 py-2 px-2"
    variant="outline-success"
    @click.prevent="addOrRemove"
  >
    {{ cartText }}
  </button>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useGtag } from "vue-gtag-next";
import { useCartStore } from "~/store/cart";

const props = defineProps({
  productId: {
    type: String,
    required: true,
  },
});
const { productId } = toRefs(props);
const { event } = useGtag();

// Store

const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);

// Computed

const inCart = computed(() => {
  return cart.value.includes(productId.value);
});

const cartText = computed(() => {
  return inCart.value ? "Remove" : "Add To Cart";
});

// Functions

function addOrRemove() {
  if (inCart.value) {
    event("remove_from_cart", {
      items: [{ item_id: productId }],
    });
    cartStore.remove(productId.value);
  } else {
    event("add_to_cart", {
      items: [{ item_id: productId }],
    });
    cartStore.add(productId.value);
  }
  console.log(productId.value);
}
</script>

<style scoped></style>
