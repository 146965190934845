<template>
  <button
    variant="outline-success"
    :disabled="!count"
    class="checkout"
    @click="redirectToCheckout"
  >
    <img
      v-if="!count"
      src="~assets/cart_icon-empty.webp"
      height="20"
      width="26"
      class="inline"
      alt="empty"
    />
    <img
      v-if="count"
      src="~assets/cart_icon-full.webp"
      height="20"
      class="inline"
      width="26"
      alt="full"
    />
    Checkout
  </button>
</template>

<script setup lang="ts">
import { loadStripe } from "@stripe/stripe-js";
import { storeToRefs } from "pinia";
import { useCartStore } from "~/store/cart";
import type { Database } from "~/types/database.types";

type Product = Database["public"]["Tables"]["products"]["Row"];

// Store

const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);

// Data

const { data: products } = useNuxtData<Product[] | null>("products");

// Computed

const count = computed(() => {
  return cart.value.length;
});

const noLongerAvailable = computed(() => {
  return cart.value.filter(
    (x) => !products.value!.map((p) => p.id).includes(x),
  );
});

watch(noLongerAvailable, () => {
  removeDeletedItems();
});

// Hooks

onMounted(() => {
  removeDeletedItems();
});

// Functions

function removeDeletedItems() {
  noLongerAvailable.value.forEach((id) => cartStore.remove(id));
}

async function redirectToCheckout() {
  const stripe = await loadStripe("pk_live_CKNtOwYPm5W5zFEjpvebV0Eq00V03hUPLY");
  try {
    const response = await $fetch<{ sessionId: string }>(
      "https://elizabethfox.art/api/store/checkout",
      {
        method: "POST",
        body: { products: cart.value },
      },
    );
    stripe?.redirectToCheckout({ sessionId: response.sessionId });
  } catch (error: any) {
    if (error.response.data.code === 1) {
      // None of the items in the cart were available anymore.
      // This shouldn't happen. We should probably show something
      // to the user in a case like this.
      cartStore.clear();
    }
  }
}
</script>

<style scoped></style>
